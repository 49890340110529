import { Box, BoxProps } from '@mui/material';
import { ReactNode } from 'react';

interface IconWrapperProps extends BoxProps {
  readonly size?: 'xxs' | 'xs' | 's' | 'm' | 'l' | number;
  readonly children: ReactNode;
}

const sizeMap = {
  xxs: '8px',
  xs: '14px',
  s: '16px',
  m: '24px',
  l: '32px',
};

export const IconWrapper = ({ size = 'm', children, ...boxProps }: IconWrapperProps) => {
  const iconSize = typeof size === 'number' ? size : sizeMap[size];

  return (
    <Box
      sx={{
        width: iconSize,
        height: iconSize,
        display: 'grid',
        placeItems: 'center',
        svg: { width: '100%', height: '100%' },
        flex: 'none',
        ...boxProps.sx,
      }}
      {...boxProps}
    >
      {children}
    </Box>
  );
};
