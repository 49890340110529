import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useDialogProps } from 'component/hooks/useDialogProps';
import { ReactNode, useEffect, useState } from 'react';
import Close from 'component/new_design/icons/Close.svg?react';
import { Step, StepStatus } from '../Step';
import { useInterval } from 'component/new_design/hooks/useInterval';

type Step = {
  readonly title: string;
  readonly description: string;
  readonly index: number;
};

const STEPS_ID = 'wizard-dialog-steps';
const WIZARD_ACTIONS_HEIGHT = 75;

export function useStepsHeight() {
  const [stepsHeight, setStepsHeight] = useState<number>(0);
  const [stepsEl, setStepsEl] = useState<HTMLDivElement | null>(null);

  useInterval(
    () => {
      const stepsEl = document.querySelector<HTMLDivElement>(`#${STEPS_ID}`);
      if (stepsEl) {
        setStepsEl(stepsEl);
      }
    },
    !stepsEl ? 100 : null
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        // 34px is the padding of the steps container
        setStepsHeight(entry.contentRect.height + 34);
      }
    });

    if (stepsEl) {
      resizeObserver.observe(stepsEl);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [stepsEl]);

  return stepsHeight;
}

export function WizardFormActions({ children }: { readonly children: ReactNode }) {
  return (
    <Box
      sx={theme => ({
        position: 'absolute',
        bottom: 0,
        height: `${WIZARD_ACTIONS_HEIGHT}px`,
        width: '100%',
        borderTop: `1px solid ${theme.palette.greys[50]}`,
        padding: 4,
      })}
    >
      {children}
    </Box>
  );
}

export function WizardFormContent({ children }: { readonly children: ReactNode }) {
  const stepsHeight = useStepsHeight();
  return (
    <Box
      sx={theme => ({
        height: 'calc(60vh - 76px)',
        overflow: 'auto',
        padding: 4,
        [theme.breakpoints.down('md')]: {
          height: `calc(60vh - ${stepsHeight + WIZARD_ACTIONS_HEIGHT}px)`,
        },
        [theme.breakpoints.down('sm')]: {
          height: `calc(100% - ${WIZARD_ACTIONS_HEIGHT}px)`,
        },
      })}
    >
      {children}
    </Box>
  );
}

export default function WizardDialog({
  activeStep,
  onClose,
  name,
  title,
  steps,
  stepNode,
  preventAccidentalClose,
}: {
  readonly activeStep: number;
  readonly onClose: () => void;
  readonly name: string;
  readonly title: ReactNode;
  readonly steps: Step[];
  readonly stepNode: ReactNode;
  readonly preventAccidentalClose: boolean;
}) {
  const theme = useTheme();
  const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const sdkDialogProps = useDialogProps(onClose, preventAccidentalClose);
  const stepsHeight = useStepsHeight();

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      id={`${name}-modal`}
      fullScreen={isSmallBreakpoint}
      open
      aria-labelledby={`${name}-title`}
      aria-describedby={`${name}-description`}
      {...sdkDialogProps}
    >
      <DialogTitle id={`${name}-title`}>
        {title}
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: '0 !important' }}>
        <Box
          sx={theme => ({
            position: 'relative',
            height: '60vh',
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
              // height minus the height of the dialog title
              height: `calc(100vh - 101px)`,
            },
          })}
        >
          <Box
            id={STEPS_ID}
            sx={theme => ({
              position: 'absolute',
              width: '320px',
              left: 0,
              height: '60vh',
              padding: 4,
              overflowY: 'auto',
              borderRight: `1px solid ${theme.palette.greys[50]}`,
              [theme.breakpoints.down('md')]: {
                position: 'relative',
                width: '100%',
                borderRight: `0px solid`,
                height: 'fit-content',
                borderBottom: `1px solid ${theme.palette.greys[50]}`,
              },
            })}
          >
            <Stack gap={4} direction={{ xs: 'row', sm: 'row', md: 'column' }}>
              {steps.map(step => {
                let status: StepStatus = 'default';
                if (step.index < activeStep) {
                  status = 'success';
                } else if (step.index === activeStep) {
                  status = 'active';
                }

                return (
                  <Step
                    key={step.index}
                    status={status}
                    number={step.index}
                    title={step.title}
                    description={step.description}
                  />
                );
              })}
            </Stack>
          </Box>
          <Box
            sx={theme => ({
              position: 'absolute',
              width: `calc(100% - 320px)`,
              right: 0,
              height: '60vh',
              overflowY: 'auto',
              borderBottom: `1px solid ${theme.palette.greys[50]}`,
              [theme.breakpoints.down('md')]: {
                height: `calc(100% - ${stepsHeight}px)`,
                position: 'relative',
                width: '100%',
                borderBottom: `0px solid`,
              },
            })}
          >
            <Box
              sx={{
                position: 'relative',
                height: '100%',
                overflow: 'hidden',
              }}
            >
              {stepNode}
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
