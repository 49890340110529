import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TableColumnType, TableRowActionType } from 'component/new_design/base/Table/types';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { Table } from 'component/new_design/base/Table';
import { EmptyState } from 'component/new_design/base/EmptyState';
import { Button, Chip, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { linkHelper } from 'linkHelper';
import { ConfirmationDialog } from 'component/new_design/base/dialogs/ConfirmationDialog';
import {
  Theme,
  useChangeActiveStatus,
  useDeleteInstalledTheme,
  useInstalledThemeList,
  useUpgradeInstalledTheme,
} from 'api/themes';
import { useSnackbar } from 'component/hooks/useSnackbar';
import ThemeIcon from 'component/new_design/icons/Layers.svg?react';
import Plus from 'component/new_design/icons/Plus.svg?react';
import Play from 'component/new_design/icons/Play.svg?react';
import UploadUp from 'component/new_design/icons/UploadUp.svg?react';
import Delete from 'component/new_design/icons/Delete.svg?react';
import { useRoleGuard } from 'component/hooks/useRoleGuard';

export function ThemesTable() {
  const { siteId } = useParams<{ siteId: string }>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const tableState = usePaginatedListState();

  const themes = useInstalledThemeList(tableState, siteId!);

  const deleteTheme = useDeleteInstalledTheme(siteId!);
  const changeThemeActiveStatus = useChangeActiveStatus(siteId!);
  const updateTheme = useUpgradeInstalledTheme(siteId!);

  const [themeNameToDelete, setThemeNameToDelete] = useState<string | undefined>(undefined);
  const [themeNameToActivate, setThemeNameToActivate] = useState<string | undefined>(undefined);
  const [themeNameToUpdate, setThemeNameToUpdate] = useState<string | undefined>(undefined);

  const showActions = !useRoleGuard(['billing_admin'], 'block');

  const columns: TableColumnType<Theme>[] = [
    {
      label: t('name'),
      key: 'name',
      renderValue: theme => (
        <Typography variant="body2" fontWeight={600}>
          {theme.title}
        </Typography>
      ),
      sortable: true,
      width: 2,
    },
    {
      label: t('description'),
      key: 'description',
      renderValue: theme => (
        <Typography
          variant="body2"
          color="greys.500"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {theme.description}
        </Typography>
      ),
      width: 0,
      minWidth: '20em',
    },
    {
      label: t('version'),
      key: 'version',
      renderValue: theme => (
        <Typography variant="body2" color="greys.500">
          {theme.version}
        </Typography>
      ),
      sortable: true,
    },
    {
      label: t('status'),
      key: 'status',
      renderValue: theme => (
        <Chip
          variant="tag"
          label={t(theme.status || '')}
          color={theme.status === 'active' ? 'success' : 'secondary'}
        />
      ),
      sortable: true,
    },
  ];

  const rowActions: TableRowActionType<Theme>[] = showActions
    ? [
        {
          label: t('activate'),
          inline: true,
          onClick: theme => setThemeNameToActivate(theme.name),
          hidden: theme => theme.status === 'active',
        },
        {
          label: t('update'),
          inline: theme => theme.status === 'active',
          onClick: theme => setThemeNameToUpdate(theme.name),
          icon: <UploadUp />,
        },
        {
          label: t('delete'),
          onClick: theme => setThemeNameToDelete(theme.name),
          color: 'reds.500',
          hidden: theme => theme.status === 'active',
          icon: <Delete />,
        },
      ]
    : [];

  const handleActivateConfirmed = async () => {
    if (!themeNameToActivate) {
      throw new Error('Theme to activate is not set');
    }

    await changeThemeActiveStatus.mutateAsync({
      theme: themeNameToActivate,
      status: 'activate',
    });

    enqueueSnackbar(t('theme_activated_successfully'), { variant: 'success' });
    setThemeNameToActivate(undefined);
  };

  const handleUpdateConfirmed = async () => {
    if (!themeNameToUpdate) {
      throw new Error('Theme to update is not set');
    }

    await updateTheme.mutateAsync({
      theme: themeNameToUpdate,
      update: true,
    });

    enqueueSnackbar(t('theme_updated_successfully'), { variant: 'success' });
    setThemeNameToDelete(undefined);
  };

  const handleDeleteConfirmed = async () => {
    if (!themeNameToDelete) {
      throw new Error('Theme to delete is not set');
    }

    await deleteTheme.mutateAsync({
      themes: themeNameToDelete,
    });

    enqueueSnackbar(t('theme_deleted_successfully'), { variant: 'success' });
    setThemeNameToDelete(undefined);
  };

  const getThemeTitle = (themeName: string) => {
    return themes.data?.data.result?.find(theme => theme.name === themeName)?.title;
  };

  return (
    <>
      <Table
        actionColumnOverrides={{
          minWidth: '130px',
        }}
        title={t('installed_themes')}
        label={t('installed_themes')}
        columns={columns}
        rowActions={rowActions}
        data={themes.data?.data.result || []}
        totalRowCount={themes.data?.data.metadata?.total || 0}
        isLoading={themes.isLoading}
        state={tableState}
        searchPlaceholder={t('search_themes')}
        enableSearch
        enablePagination
        emptyState={
          <EmptyState
            icon={<ThemeIcon />}
            title={t('no_themes_yet')}
            description={t('no_themes_description')}
            action={
              <Button
                href={linkHelper.newDesign.sites.siteDetails.themes.discover.getLink({
                  siteId: siteId || '',
                })}
                size="medium"
                variant="contained"
                color="primary"
                startIcon={<Plus />}
              >
                {t('install_theme')}
              </Button>
            }
          />
        }
        rowIdKey="name"
      />

      {themeNameToDelete ? (
        <ConfirmationDialog
          title={t('delete_theme')}
          description={
            <Trans
              i18nKey="are_you_sure_you_want_to_delete"
              values={{ name: getThemeTitle(themeNameToDelete) }}
            />
          }
          confirmColor="error"
          confirmText={t('delete')}
          onConfirm={handleDeleteConfirmed}
          onClose={() => setThemeNameToDelete(undefined)}
        />
      ) : null}
      {themeNameToActivate ? (
        <ConfirmationDialog
          title={t('activate_theme')}
          description={
            <Trans
              i18nKey="are_you_sure_you_want_to_activate"
              values={{ name: getThemeTitle(themeNameToActivate) }}
            />
          }
          confirmText={t('activate')}
          onConfirm={handleActivateConfirmed}
          onClose={() => setThemeNameToActivate(undefined)}
        />
      ) : null}
      {themeNameToUpdate ? (
        <ConfirmationDialog
          title={t('update_theme')}
          description={
            <Trans
              i18nKey="are_you_sure_you_want_to_update"
              values={{ name: getThemeTitle(themeNameToUpdate) }}
            />
          }
          confirmColor="error"
          confirmText={t('update')}
          onConfirm={handleUpdateConfirmed}
          onClose={() => setThemeNameToUpdate(undefined)}
        />
      ) : null}
    </>
  );
}
