import { Navigate, Route, Routes } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import { linkHelper } from 'linkHelper';

const UserList = lazy(() => import('../../partial/UserList'));
// const UserProfile = lazy(() => import('../../partial/UserProfile'));

export default function Users() {
  return (
    <Routes>
      <Route
        index
        element={
          <Suspense>
            <UserList />
          </Suspense>
        }
      />
      {/* <Route
        path={linkHelper.newDesign.settings.users.account.profile.path}
        element={
          <Suspense>
            <UserProfile userType="account" />
          </Suspense>
        }
      />
      <Route
        path={linkHelper.newDesign.settings.users.site.profile.path}
        element={
          <Suspense>
            <UserProfile userType="site" />
          </Suspense>
        }
      /> */}
      <Route
        path="*"
        element={<Navigate to={linkHelper.newDesign.settings.users.getLink()} replace />}
      />
    </Routes>
  );
}
