import { useSites, useSitesUsers } from 'api/site';
import { getIsWpSiteControl } from 'utils';
import { createStaticPaginatedListState } from './usePaginatedListState';

export const useUnpaginatedSitesList = () => {
  const isWpSiteControl = getIsWpSiteControl();

  const paginationState = createStaticPaginatedListState();

  const sitesData = useSites(paginationState, { enabled: !isWpSiteControl });
  const userSitesData = useSitesUsers(paginationState, { enabled: isWpSiteControl });

  return isWpSiteControl ? userSitesData : sitesData;
};
