import { Stack } from '@mui/material';
import { BillingCurrentPlan } from './CurrentPlan';

export function BillingLanding() {
  return (
    <Stack gap={4}>
      <BillingCurrentPlan />
    </Stack>
  );
}
