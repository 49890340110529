import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { components } from 'openapi-types';

export interface CurrentPlan {
  name: string;
  type: 'yearly' | 'monthly';
  plan_type: 'personal' | 'business';
  plan_id: number; // id of the current billing plan
  created_at: string; //ISO Date
  status: 'active' | 'pending' | 'cancelled'; // not sure how many other statuses will be supported
  dollar_amount: number;
  next_billing_date: string; //ISO Date
}

type CurrentPlanReturnType = components['schemas']['BasicResponse'] & {
  result: CurrentPlan;
};

const mockPlan = (plan?: Partial<CurrentPlan>): CurrentPlan => {
  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + 30); // Set now + 30 days as the new date

  return {
    name: 'Free',
    type: 'monthly',
    plan_id: 12345,
    created_at: new Date().toISOString(),
    status: 'active',
    plan_type: 'business',
    dollar_amount: 30.0,
    next_billing_date: futureDate.toDateString(),
    ...plan,
  };
};

// mocked until api is available
export const useGetCurrentPlan = () => {
  return useQuery({
    queryKey: ['billing', 'plans', 'current'],

    queryFn: async () => {
      return new Promise<CurrentPlanReturnType>(resolve => {
        setTimeout(() => {
          resolve({
            result: mockPlan(),
          });
        }, 2000);
      });
    },

    retry: 0,
    staleTime: 5000,
    gcTime: 10,
  });
};

type CancelPlanReturnType = components['schemas']['BasicResponse'] & {
  success: boolean;
};

export type RequestCancelPlanData = {
  description: string;
};

export const useRequestCancelPlan = (planId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [`setSiteLabel`],

    mutationFn: async (data: RequestCancelPlanData) => {
      return new Promise<CancelPlanReturnType>(resolve => {
        setTimeout(() => {
          resolve({
            success: true,
          });
        }, 2000);
      });
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['billing', 'plans', 'current'],
      });
    },
  });
};
