import { Box, IconButton, Link as MuiLink, Popover, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

// icons
import LockClosed from 'component/new_design/icons/LockClose.svg?react';
import { Link, useNavigate } from 'react-router-dom';
import { linkHelper } from 'linkHelper';

export function SiteLockedButton({ siteId }: { readonly siteId: string }) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const modalWidth = 240;

  return (
    <>
      <IconButton
        size="medium"
        onClick={event => setAnchorEl(event.currentTarget)}
        sx={{
          backgroundColor: 'greens.200',
          color: 'greens.900',
          my: '-9px',
          borderRadius: 'var(--border-radius-m)',
          '&:hover': {
            backgroundColor: 'greens.200',
          },
        }}
      >
        <LockClosed />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: modalWidth / 2, // half of the modal
        }}
      >
        <Box
          sx={{
            padding: 3,
            width: `${modalWidth}px`,
          }}
        >
          <Stack gap={3}>
            <Stack gap={2}>
              <Typography variant="caption" color="greys.900" fontWeight={600}>
                {t('site_locked_popover_title')}
              </Typography>
              <Typography variant="caption" color="greys.500">
                <Trans i18nKey={t('site_locked_popover_description_new')} />
              </Typography>
            </Stack>
            <Stack gap={1}>
              <MuiLink
                sx={{
                  fontSize: '12px',
                  fontWeight: 600,
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
                component={Link}
                to={`${linkHelper.newDesign.sites.siteDetails.advanced.getLink({ siteId })}#lock-site`}
                onClick={() => {
                  setAnchorEl(null);
                }}
              >
                {t('go_to_unlock_site')}
              </MuiLink>
            </Stack>
          </Stack>
        </Box>
      </Popover>
    </>
  );
}
