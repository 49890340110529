import { Chip } from '@mui/material';
import { CurrentPlan } from 'api/billing';
import { useTranslation } from 'react-i18next';

export function BillingPlanStatusChip({ status }: { readonly status?: CurrentPlan['status'] }) {
  const { t } = useTranslation();

  switch (status) {
    case 'active':
      return <Chip variant="tag" color="success" label={t('active')} />;
    case 'pending':
      return <Chip variant="tag" color="warning" label={t('active')} />;
    case 'cancelled':
      return <Chip variant="tag" label={t('active')} />;
    default:
      return null;
  }
}
