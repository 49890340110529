import { useTranslation } from 'react-i18next';
import ChevronDown from '../icons/ChevronDown.svg?react';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { useContext, useId, useState } from 'react';
import { IconWrapper } from '../base/IconWrapper';
import { BackgroundTaskContext } from 'component/root/BackgroundTaskProvider';
import { Task } from 'api/profile';
import { EMPTY_VALUE_PLACEHOLDER } from 'utils/formatting';
import { isTaskWithSiteInfo } from 'utils/tasks';
import Envelope from '../icons/Envelope.svg?react';
import CheckCircle from '../icons/CheckCircle.svg?react';

const TaskProgressIndicator = ({ progress }: { readonly progress?: number }) => {
  const { t } = useTranslation();

  if (progress === 100) {
    return <Chip variant="tag" label={t('completed')} color="success" />;
  } else if (progress) {
    return <Chip variant="tag" label={`${progress}%`} color="secondary" />;
  } else {
    return <CircularProgress size={18} />;
  }
};

export const Tasks = () => {
  const { t } = useTranslation();

  const { allTasks, tasksInProgress } = useContext(BackgroundTaskContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const buttonId = useId();
  const popoverId = useId();

  const progress =
    tasksInProgress.reduce((acc, task) => acc + (task.perc || 0), 0) / tasksInProgress.length;

  const getTaskMessage = (task: Task) => {
    switch (task.task_type) {
      case 'automated_restore':
        return t('automated_restore');
      case 'backup':
        return t('create_backup');
      case 'publish':
        return t('publish_to_production');
      case 'restore':
        return t('restore_backup');
      case 'staging':
        return t('create_staging');
      default:
        return EMPTY_VALUE_PLACEHOLDER;
    }
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id={buttonId}
        aria-haspopup="true"
        aria-controls={popoverId}
        aria-expanded={anchorEl ? 'true' : 'false'}
        variant="contained"
        color="header"
        size="large"
        sx={{ flex: 'none', gap: 2 }}
        startIcon={
          tasksInProgress.length ? (
            <CircularProgress color="primary" size={18} value={progress} />
          ) : (
            <IconWrapper size={18} color="greys.400">
              <CheckCircle />
            </IconWrapper>
          )
        }
        endIcon={<ChevronDown />}
        onClick={handleOpen}
      >
        {tasksInProgress.length ? t('still_working', { value: progress }) : t('tasks')}
      </Button>
      {!!anchorEl && (
        <Popover
          id={popoverId}
          open
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Stack p={3} gap={1} sx={{ width: '400px' }}>
            <Typography variant="caption" fontWeight={600}>
              {tasksInProgress.length ? t('processing') : t('no_tasks_in_progress')}
            </Typography>
            <Typography variant="caption" color="greys.500">
              {tasksInProgress.length
                ? t('tasks_in_progress_description')
                : t('no_tasks_in_progress_description')}
            </Typography>
          </Stack>

          {allTasks.length ? (
            <>
              <Divider />

              <Stack
                p={1}
                sx={{
                  overflowY: 'auto',
                  maxHeight: '50vh',
                }}
              >
                {allTasks.toReversed().map(task => (
                  <Stack
                    key={task.id}
                    p={2}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack gap={0.5}>
                      <Typography variant="body2" fontWeight={600}>
                        {getTaskMessage(task)}
                      </Typography>
                      <Typography variant="caption" color="greys.500">
                        {isTaskWithSiteInfo(task)
                          ? task.site_info?.domain
                          : EMPTY_VALUE_PLACEHOLDER}
                      </Typography>
                    </Stack>
                    <TaskProgressIndicator progress={task.perc} />
                  </Stack>
                ))}
              </Stack>

              <Divider />

              <Box p={3} sx={{ textAlign: 'right' }}>
                <Button
                  variant="text"
                  startIcon={
                    <IconWrapper size="xs">
                      <Envelope />
                    </IconWrapper>
                  }
                  size="small"
                  disabled={!tasksInProgress.length}
                >
                  {t('send_email_when_completed')}
                </Button>
              </Box>
            </>
          ) : null}
        </Popover>
      )}
    </>
  );
};
