import { Box, Button, Card, CardHeader, Divider, Skeleton, Stack, Typography } from '@mui/material';
import { useGetCurrentPlan } from 'api/billing';
import { useTranslation } from 'react-i18next';
import { formatLocaleDate } from 'utils/dateFormat';
import { BillingPlanStatusChip } from './PlanStatusChip';
import { BillingCancelPlan } from './CancelPlan';

// icons
import PencilEdit from '../../../icons/PencilEdit.svg?react';

export function BillingCurrentPlan() {
  const { t, i18n } = useTranslation();
  const { data, isLoading } = useGetCurrentPlan();

  if (isLoading) {
    return (
      <Card>
        <CardHeader title={<Skeleton width="250px" />} action={<Skeleton width="150px" />} />
        <Box sx={theme => ({ padding: theme.spacing(8, 7), paddingBottom: 4 })}>
          <Stack gap={2}>
            <Stack direction="row" gap={2} alignItems="center">
              <Skeleton width="125px" height="40px" />
              <Skeleton width="100px" height="1.25rem" sx={{ marginTop: '10px' }} />
            </Stack>

            <Stack divider={<Divider flexItem />} gap={4}>
              <Stack direction="row" justifyContent="space-between" width="100%">
                <Skeleton width="150px" height="1rem" />
                <Skeleton width="150px" height="1rem" />
              </Stack>
              <Skeleton width="150px" height="1rem" />
            </Stack>
          </Stack>
        </Box>
      </Card>
    );
  }

  const usDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const plan = data?.result;

  // no plans, we don't want to show an empty card
  if (!plan) {
    return null;
  }

  return (
    <Card>
      <CardHeader
        title={
          <Box>
            <Typography
              variant="body1"
              color="greys.900"
              fontWeight="600"
              sx={{ display: 'inline' }}
            >
              {t('current_plan_title', {
                planType: (plan && t(plan.plan_type)) || '',
              })}
            </Typography>
            <Box marginLeft={2} display="inline">
              <BillingPlanStatusChip status={plan.status} />
            </Box>
          </Box>
        }
        action={
          <Stack gap={2} direction="row">
            <BillingCancelPlan planId={plan.plan_id} />
            <Button variant="contained" color="primary" startIcon={<PencilEdit />}>
              {t('edit_plan')}
            </Button>
          </Stack>
        }
      />
      <Stack>
        <Box sx={theme => ({ padding: theme.spacing(8, 7), paddingBottom: 4 })}>
          <Stack gap={2}>
            <Stack direction="row" gap={2} alignItems="baseline">
              <Typography variant="h2" color="greys.900" fontWeight="700">
                {plan.name}
              </Typography>
              <Typography variant="body2" color="greys.600" fontWeight="600">
                {'/'}
                {t(plan.type).toLocaleLowerCase()}
              </Typography>
            </Stack>
            <Stack divider={<Divider flexItem />} gap={4}>
              <Stack direction="row" justifyContent="space-between" width="100%">
                <Typography variant="caption" color="greys.400">
                  {t('first_payment', { value: usDollar.format(plan.dollar_amount) })}
                </Typography>
                <Typography variant="caption" color="greys.400">
                  {t('next_due_date', {
                    value: formatLocaleDate({
                      locale: i18n.language,
                      value: plan.next_billing_date,
                      includeTime: false,
                      options: {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      },
                    }),
                  })}
                </Typography>
              </Stack>
              <Typography variant="caption" color="greys.400">
                {t('registered', {
                  value: formatLocaleDate({
                    locale: i18n.language,
                    value: plan.created_at,
                    includeTime: false,
                    options: {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    },
                  }),
                })}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
}
