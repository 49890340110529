import { useContext, useEffect, useState } from 'react';
import { Button, Stack, CircularProgress, Skeleton } from '@mui/material';
import { useCreateStaging, SiteDetail, useDeleteStaging, usePublishStaging } from 'api/site';
import { useNavigate } from 'react-router-dom';
import { ConfirmationDialog } from '../../../base/dialogs/ConfirmationDialog';
import { Trans, useTranslation } from 'react-i18next';
import { Select } from '../../../base/forms/Select';
import ActionCard from '../../ActionCard';
import { linkHelper } from 'linkHelper';
import { useRoleGuard } from 'component/hooks/useRoleGuard';
import { RoleGuard } from 'component/base/RoleGuard';
import { useBuildSelectOptions } from 'component/hooks/useBuildSelectOptions';
import { BackgroundTaskContext } from 'component/root/BackgroundTaskProvider';
import { IconWrapper } from '../../../base/IconWrapper';
import { LoadingButton } from '@mui/lab';
import { TaskWithSiteInfo } from 'api/profile';
import { isTaskWithSiteInfo } from 'utils/tasks';
import PlayCircle from '../../../icons/PlayCircle.svg?react';
import GitFork from '../../../icons/GitFork.svg?react';
import DeleteDustbin from '../../../icons/DeleteDustbin.svg?react';
import UploadUp from '../../../icons/UploadUp.svg?react';

interface SiteEnvironmentCardProps {
  readonly siteDetails?: SiteDetail;
  readonly isLoading: boolean;
}

type SiteEnvironmentData = {
  environment: 'production' | 'staging';
  displayedId: number;
  productionId: number;
  stagingId: number | undefined;
};

function getSiteEnvironmentData(siteDetail: SiteDetail | undefined) {
  let displayedId;
  let productionId;
  let stagingId;

  if (siteDetail?.id && siteDetail.staging) {
    // Production site with staging
    displayedId = siteDetail.id;
    productionId = siteDetail.id;
    stagingId = siteDetail.staging.staging_id;
  } else if (siteDetail?.id && siteDetail.production) {
    // Staging site
    displayedId = siteDetail.id;
    productionId = siteDetail.production;
    stagingId = siteDetail.id;
  } else if (siteDetail?.id) {
    // Production site with no staging
    displayedId = siteDetail.id;
    productionId = siteDetail.id;
    stagingId = undefined;
  }

  return {
    environment: displayedId === productionId ? 'production' : 'staging',
    displayedId: displayedId!,
    productionId: productionId!,
    stagingId: stagingId,
  } satisfies SiteEnvironmentData;
}

function useCompletedTaskRedirection(ids: SiteEnvironmentData) {
  const { newlyCompletedTasks } = useContext(BackgroundTaskContext);
  const navigate = useNavigate();

  const taskTypes = ['staging', 'publish'];

  useEffect(() => {
    // Find the completed task for the current site
    const completedTask = (newlyCompletedTasks as TaskWithSiteInfo[]).find(
      task =>
        isTaskWithSiteInfo(task) &&
        (task.site_id === ids.productionId || task.site_id === ids.stagingId) &&
        taskTypes.includes(task.task_type as string)
    );

    if (completedTask?.task_type === 'staging') {
      const newStagingId = JSON.parse(
        completedTask?.meta_data as unknown as string
      )?.staging_site_id;

      if (!newStagingId) {
        return;
      }
      navigate(
        linkHelper.newDesign.sites.siteDetails.getLink({
          siteId: newStagingId,
        })
      );
    } else if (completedTask?.task_type === 'publish') {
      navigate(
        linkHelper.newDesign.sites.siteDetails.getLink({
          siteId: String(ids.productionId),
        })
      );
    }
  }, [newlyCompletedTasks]);
}

export function SiteEnvironmentCard({ siteDetails, isLoading }: SiteEnvironmentCardProps) {
  const site = getSiteEnvironmentData(siteDetails);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useCompletedTaskRedirection(site);

  const createStaging = useCreateStaging(String(site.productionId));
  const deleteStaging = useDeleteStaging(String(site.productionId));
  const publishStaging = usePublishStaging(String(site.productionId));

  const [showConfirmation, setShowConfirmation] = useState<'delete' | 'publish' | null>(null);
  const { buildOptions } = useBuildSelectOptions();

  const { tasksInProgress } = useContext(BackgroundTaskContext);

  const siteEnvironmentTaskInProgress = tasksInProgress.find(
    task =>
      isTaskWithSiteInfo(task) &&
      (task.site_id === site.stagingId || task.site_id === site.productionId) &&
      (task.task_type === 'publish' || task.task_type === 'staging')
  );

  const billingAdminBlock = useRoleGuard(['billing_admin'], 'block');

  // TODO - confirm with Josip
  if (isLoading) {
    return <Skeleton variant="rounded" height="80px" />;
  }

  if (siteEnvironmentTaskInProgress?.task_type === 'staging') {
    return (
      <ActionCard
        icon={<CircularProgress size={24} />}
        paletteColor="greys"
        title={t('creating_staging')}
        description={t('it_can_take_few_seconds')}
      />
    );
  }

  if (site.environment === 'production' && !site.stagingId) {
    return (
      <ActionCard
        actions={
          <RoleGuard roles={['billing_admin']} type="block">
            <LoadingButton
              variant="contained"
              color="tertiary"
              fullWidth
              startIcon={<GitFork />}
              loading={createStaging.isPending}
              onClick={() => createStaging.mutateAsync()}
            >
              {t('create_staging')}
            </LoadingButton>
          </RoleGuard>
        }
        paletteColor="oranges"
        icon={<PlayCircle />}
        title={t('production_environment')}
        description={t('production_environment_description')}
      />
    );
  }

  return (
    <>
      {showConfirmation === 'delete' && (
        <ConfirmationDialog
          confirmColor="error"
          title={t('delete_staging')}
          description={
            <Trans
              i18nKey={'delete_staging_confirmation_description'}
              values={{ site: siteDetails?.domain }}
            />
          }
          onClose={() => setShowConfirmation(null)}
          onConfirm={async () => {
            await deleteStaging.mutateAsync();
            navigate(
              linkHelper.newDesign.sites.siteDetails.getLink({
                siteId: String(site.productionId),
              })
            );
          }}
        />
      )}
      {showConfirmation === 'publish' && (
        <ConfirmationDialog
          title={t('publish_staging_confirmation_title')}
          description={t('publish_staging_confirmation_description')}
          onClose={() => setShowConfirmation(null)}
          onConfirm={publishStaging.mutateAsync}
        />
      )}
      <ActionCard
        paletteColor={site.environment === 'production' ? 'oranges' : 'greys'}
        leftContent={
          <Select
            disabled={billingAdminBlock}
            aria-label={t('site_environment')}
            variant={site.environment === 'production' ? 'outlined' : 'filled'}
            startAdornment={
              <IconWrapper size="s" color="greys.500" mr={1}>
                {site.environment === 'production' ? <PlayCircle /> : <GitFork />}
              </IconWrapper>
            }
            value={site.environment}
            options={buildOptions('production', 'staging')}
            onChange={value => {
              navigate(
                linkHelper.newDesign.sites.siteDetails.getLink({
                  siteId: String(value === 'staging' ? site.stagingId : site.productionId),
                })
              );
            }}
          />
        }
        description={
          site.environment === 'production' ? t('production_description') : t('staging_description')
        }
        actions={
          site.environment === 'production' ? null : (
            <RoleGuard roles={['billing_admin']} type="block">
              <Stack gap={4} direction={{ xs: 'column', sm: 'row' }}>
                <Button
                  variant="contained"
                  color="tertiary"
                  sx={theme => ({
                    color: theme.palette.reds[500],
                  })}
                  disabled={!!siteEnvironmentTaskInProgress}
                  startIcon={<DeleteDustbin />}
                  onClick={() => {
                    setShowConfirmation('delete');
                  }}
                >
                  {t('delete_staging')}
                </Button>
                <LoadingButton
                  sx={{
                    minWidth: '150px',
                  }}
                  variant="contained"
                  startIcon={<UploadUp />}
                  onClick={() => {
                    setShowConfirmation('publish');
                  }}
                  loading={siteEnvironmentTaskInProgress?.task_type === 'publish'}
                  disabled={!!siteEnvironmentTaskInProgress}
                >
                  {t('publish_to_production')}
                </LoadingButton>
              </Stack>
            </RoleGuard>
          )
        }
      />
    </>
  );
}
