import { Button, Stack } from '@mui/material';
import { RequestCancelPlanData, useRequestCancelPlan } from 'api/billing';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { FormDialog } from '../../../base/dialogs/FormDialog';
import { TextField } from '../../../base/forms/TextField';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'component/hooks/useSnackbar';

export function BillingCancelPlan({ planId }: { readonly planId: number }) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const methods = useForm<RequestCancelPlanData>({
    defaultValues: {
      description: '',
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync } = useRequestCancelPlan(planId);

  const onSubmit = async (data: RequestCancelPlanData) => {
    await mutateAsync(
      {
        ...data,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(t('cancellation_success_message'), {
            key: 'requestCancellation',
            variant: 'success',
          });
        },
      }
    );

    handleClose();
  };

  function handleClose() {
    methods.reset();
    setShowModal(false);
  }

  return (
    <>
      <FormDialog
        form={
          <Stack spacing={4} direction="column" gap={2}>
            <TextField
              label={t('account_cancellation_request_description')}
              placeholder={t('your_description')}
              rows="3"
              multiline
              name="description"
              fullWidth
              rules={{ required: true }}
            />
          </Stack>
        }
        methods={methods}
        onSubmit={onSubmit}
        onClose={handleClose}
        open={showModal}
        title={t('account_cancellation_request')}
        submitText={t('request')}
        submitButtonColor="error"
      />
      <Button variant="contained" color="secondary" onClick={() => setShowModal(true)}>
        {t('cancel_plan')}
      </Button>
    </>
  );
}
