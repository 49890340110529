import { Box, Card, CardActions, CardContent, CardHeader, Stack } from '@mui/material';
import { Table } from './Table';
import { TableRowType, TableProps } from './types';
import { PaginationControls } from 'component/new_design/base/PaginationControls';
import { useDebouncedInputProps } from 'component/hooks/useDebouncedInput';
import { SearchInput } from 'component/new_design/base/SearchInput';
import { usePaginatedListLoadingState } from 'component/hooks/usePaginatedListLoadingState';

export const CardTable = <T extends TableRowType>({
  actionColumnOverrides,
  headerComponent,
  title,
  label,
  description,
  isLoading,
  data,
  totalRowCount,
  state,
  rowActions,
  columns,
  enableSearch = false,
  enablePagination = true,
  tableBar,
  children,
  renderExpandedRow,
  searchPlaceholder = '',
  rowIdKey = 'id',
  emptyState,
}: TableProps<T>) => {
  const { params, setSearch } = state;
  const searchInputProps = useDebouncedInputProps(params.search, setSearch);
  const loadingState = usePaginatedListLoadingState(!!data.length, isLoading, params);

  return (
    <Card component="section">
      <Stack direction="row" alignItems="center">
        {headerComponent ? (
          <Box sx={{ pl: 4, pt: 4, display: 'grid', placeItems: 'center' }}>{headerComponent}</Box>
        ) : null}
        <CardHeader
          title={title}
          subheader={description}
          action={
            loadingState === 'noData' ? null : (
              <>
                {enableSearch ? (
                  <SearchInput
                    {...searchInputProps}
                    placeholder={searchPlaceholder}
                    aria-label={searchPlaceholder}
                  />
                ) : null}
                {children}
              </>
            )
          }
        />
      </Stack>

      <CardContent sx={loadingState === 'noData' ? { height: '300px' } : null}>
        <>
          {tableBar}
          <Table
            actionColumnOverrides={actionColumnOverrides}
            columns={columns}
            data={data}
            emptyState={emptyState}
            isLoading={isLoading}
            label={label}
            state={state}
            renderExpandedRow={renderExpandedRow}
            rowActions={rowActions}
            rowIdKey={rowIdKey}
          />
        </>
      </CardContent>

      {enablePagination ? (
        <CardActions>
          <PaginationControls state={state} totalRowCount={totalRowCount} />
        </CardActions>
      ) : null}
    </Card>
  );
};
