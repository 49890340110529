import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import { SetSiteLabelData, useSetSiteLabel } from 'api/site';
import { components } from 'openapi-types';
import { FormDialog } from '../base/dialogs/FormDialog';
import { TextField } from '../base/forms/TextField';

interface DomainLabelFormValue {
  label: string;
}

export function ChangeLabelDialog({
  site,
  onClose,
}: {
  readonly site: Pick<components['schemas']['Site'], 'id' | 'label' | 'domain'>;
  readonly onClose: () => unknown;
}) {
  const { t } = useTranslation();
  const { mutateAsync } = useSetSiteLabel(site.id!);

  const methods = useForm<DomainLabelFormValue>({
    defaultValues: {
      label: site?.label ?? '',
    },
    mode: 'onChange',
  });

  const handleSubmit = async (data: SetSiteLabelData) => {
    await mutateAsync({ ...data });
    onClose();
  };

  return (
    <FormDialog
      methods={methods}
      onSubmit={handleSubmit}
      onClose={onClose}
      open={Boolean(site?.id)}
      title={t('change_site_label')}
      submitText={t('change')}
      form={
        <Stack spacing={4} direction="column">
          <Typography variant="body2">
            {t('change_site_label_description', { domain: site?.domain })}
          </Typography>
          <TextField
            name="label"
            label={t('site_label')}
            color="primary"
            rules={{ required: true }}
          />
        </Stack>
      }
    />
  );
}
