import { useState } from 'react';
import { Table } from 'component/new_design/base/Table';
import { useFtpAccounts, useDeleteFtpAccount, FtpAccount } from 'api/ftpAccounts';
import { AddFtpAccount } from './AddFtpAccount';
import { UpdateFtpAccount } from './UpdateFtpAccount';
import { useParams } from 'react-router';
import { ConfirmationDialog } from '../base/dialogs/ConfirmationDialog';
import { Trans, useTranslation } from 'react-i18next';
import DeleteDustbin from '../icons/DeleteDustbin.svg?react';
import PencilEdit from '../icons/PencilEdit.svg?react';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { TableColumnType } from 'component/new_design/base/Table/types';
import { EmptyState } from '../base/EmptyState';
import UserDefault from '../icons/UserDefault.svg?react';

interface Props {
  readonly domain: string;
}

export function FtpAccounts({ domain }: Props) {
  const { siteId = '' } = useParams<{ siteId: string }>();

  const { mutateAsync } = useDeleteFtpAccount({
    id: siteId,
  });

  const tableState = usePaginatedListState(
    {
      urlStatePrefix: 'ftp',
      searchKey: 'username',
    },
    { sortBy: 'user', sortDirection: 'asc' }
  );

  const { isLoading, data } = useFtpAccounts(siteId, tableState);
  const [ftpAccountToDelete, setFtpAccountToDelete] = useState<FtpAccount | null>(null);
  const [ftpAccountToUpdate, setFtpAccountToUpdate] = useState<FtpAccount | null>(null);
  const { t } = useTranslation();

  const columns: (TableColumnType<FtpAccount> | null)[] = [
    {
      label: t('username'),
      key: 'user',
      sortable: true,
    },
    {
      label: t('directory'),
      key: 'reldir',
      sortable: false,
    },
    {
      label: t('usage'),
      key: 'diskused',
      renderValue: ftpAccount => t('mb', { val: Math.round(Number(ftpAccount.diskused)) }),
      sortable: false,
    },
  ];

  const handleDeleteConfirmed = async () => {
    if (!ftpAccountToDelete || !ftpAccountToDelete.user) {
      throw new Error('No FTP account to delete');
    }

    await mutateAsync({ username: ftpAccountToDelete.user });
  };

  return (
    <>
      <Table
        title={t('ftp_accounts')}
        label="Site FTP Accounts Table"
        rowActions={[
          {
            onClick: async ftpAccount => {
              setFtpAccountToUpdate(ftpAccount);
            },
            icon: <PencilEdit />,
            label: t('update'),
          },
          {
            icon: <DeleteDustbin />,
            label: t('delete'),
            onClick: ftpAccount => {
              setFtpAccountToDelete(ftpAccount);
            },
            color: 'reds.500',
          },
        ]}
        columns={columns}
        data={data?.data.result ?? []}
        totalRowCount={data?.data.metadata?.total ?? 0}
        isLoading={isLoading}
        state={tableState}
        enablePagination
        enableSearch
        searchPlaceholder={t('search_sites_label')}
        emptyState={
          <EmptyState
            icon={<UserDefault />}
            title={t('no_data_yet')}
            action={<AddFtpAccount domain={domain} />}
          />
        }
        rowIdKey="user"
      >
        <AddFtpAccount domain={domain} />
      </Table>
      {ftpAccountToDelete ? (
        <ConfirmationDialog
          title={t('delete_ftp_account')}
          description={
            <Trans
              i18nKey="delete_ftp_account_confirmation_description"
              values={{ user: ftpAccountToDelete.user }}
            />
          }
          onClose={() => setFtpAccountToDelete(null)}
          onConfirm={handleDeleteConfirmed}
          confirmText={t('delete')}
          confirmColor="error"
        />
      ) : null}
      {ftpAccountToUpdate ? (
        <UpdateFtpAccount
          ftpAccount={ftpAccountToUpdate}
          onClose={() => setFtpAccountToUpdate(null)}
        />
      ) : null}
    </>
  );
}
